<style lang="scss">
#client_service {
  .symbol-label {
    height: 40px !important;
    width: 70px !important;
  }
}
</style>
<template>
  <div class="row" id="client_service">

    <div v-if="isQuickBookSyncing" style="position: absolute;
    width: 100%;
    z-index: 1;
    background: rgb(71 71 71 / 20%);
    height: 100%;
    display: flex
;">
      <vDataLoader></vDataLoader>
    </div>
    <div class="col-md-12" style="background: white">
      <table class="table table-striped">
        <thead>
          <tr>
            <th colspan="3">
              <input
                v-model="searchInput"
                type="text"
                placeholder="Search Service Name"
                class="form-control"
              />
            </th>
            <th></th>

            <th colspan="6">
              <router-link
                :to="{ name: 'client.service.create' }"
                v-if="currentUser.u.roles == 'admin'"
              >
                <div class="btn btn-info" style="float: right">
                  <i class="fa fa-plus"></i> Add Service
                </div>
              </router-link>
            </th>
          </tr>
          <tr>
            <th>SN</th>
            <th>Image</th>
            <th>Name</th>
            <th scope="col">Description</th>
            <th scope="col">Type</th>
            <th>Status</th>
            <th>QuickBooks Synced</th>
            <th>Drug Testing</th>
            <th>Featured</th>
            <th
              width="70px"
              style="width: 14%;"
              scope="col"
              v-if="currentUser.u.roles == 'admin'"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody v-if="!loadingData && filteredServices.length > 0">
          <tr v-for="(item, index) in filteredServices" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                <div
                  class="symbol-label"
                  :style="
                    'background-size: contain;background-image: url(' +
                      getBServices.url +
                      item.image +
                      ')'
                  "
                ></div>
              </div>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ globalSubStr(item.description, 50) }}</td>
            <td>{{ item.service_type }}</td>
            <td>
              <i class="fa fa-times text-danger" v-if="!item.is_active"></i>
              <i class="fa fa-check text-success" v-if="item.is_active"></i>
            </td>
            <td class="text-center">
              <i v-if="!item.is_quickbooks_synced" class="fa fa-times text-danger"></i>
              <i v-if="item.is_quickbooks_synced" class="fa fa-check text-success"></i>
            </td>
            <td>
              <i
                class="fa fa-times text-danger"
                v-if="!item.is_drug_testing"
              ></i>
              <i
                class="fa fa-check text-success"
                v-if="item.is_drug_testing"
              ></i>
            </td>
            <td>
              <i class="fa fa-times text-danger" v-if="!item.is_featured"></i>
              <i class="fa fa-check text-success" v-if="item.is_featured"></i>
            </td>

            <td v-if="currentUser.u.roles == 'admin'">
              <a @click="editData(item.id)" class="btn btn-info btn-sm mr-3 mt-2">
                Edit</a
              >
              <a @click="syncQuickBooks(item.id)" class="btn btn-success mr-3 mt-2">
                {{ item.is_quickbooks_synced ? 'Update' : 'Sync' }} QuickBooks</a
              >
              <a
                @click="deleteItem(item.id, index)"
                class="btn btn-danger btn-sm mr-3 mt-2"
              >
                Delete</a
              >
            </td>
          </tr>
        </tbody>

        <tbody v-if="!loadingData && filteredServices.length < 1">
          <tr>
            <td colspan="8" class="text-center">No record(s) found</td>
          </tr>
        </tbody>

        <tbody v-if="loadingData">
          <tr>
            <th class="text-center" colspan="10">
              <vDataLoader></vDataLoader>
            </th>
          </tr>
        </tbody>
        <!----><!---->
      </table>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_B_SERVICES,
  DELETE_B_SERVICE,
  SYNC_QUICKBOOK_SERVICES
} from "@/core/services/store/actions.type";
import vDataLoader from "@/components/frontend/spinner.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Services",
          route: ""
        }
      ],
      loadingData: false,
      searchInput: "",
      imgBaseUrl: "",
      isQuickBookSyncing: false,
    };
  },
  components: {
    vDataLoader
  },
  computed: {
    ...mapGetters(["getBServices", "currentUser"]),
    filteredServices() {
      let tempService = this.getBServices.data;
      //process search input
      if (this.searchInput != "" && this.searchInput) {
        tempService = tempService.filter(item => {
          return item.name
            .toUpperCase()
            .includes(this.searchInput.toUpperCase());
        });
      }
      return tempService;
    }
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getServiceData();
  },
  methods: {
    deleteItem(id, index) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover!",

        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        showCloseButton: true,
        everseButtons: true
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          this.$store
            .dispatch(DELETE_B_SERVICE, id)
            .then(data => {
              this.getBServices.data.splice(index, 1);
              this.$toastr.s(data.msg);
            })
            .catch(err => {
              this.$toastr.e(err.error);
            });
        }
      });
    },
    syncQuickBooks(id){
      this.isQuickBookSyncing = true;
      this.$store.dispatch(SYNC_QUICKBOOK_SERVICES, id)
      .then(r => {
        this.isQuickBookSyncing = false;
        this.$toastr.s("Synced Successfully");
        this.getServiceData();
      })
      .catch(e => {
        this.isQuickBookSyncing = false;
        this.$toastr.e(e.msg);
      });
    },
    editData(id) {
      this.$router.push({ name: "client.service.edit", params: { id: id } });
    },
    getServiceData() {
      this.loadingData = true;
      this.$store
        .dispatch(FETCH_B_SERVICES)
        .then(() => {
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    }
  }
};
</script>

<style></style>
